/* eslint-disable no-restricted-globals */
import { cnpj, cpf } from 'cpf-cnpj-validator';

export const validatorPositive = value => {
    if (value >= 0) {
        return true;
    }
    return false;
};

export const validatorPassword = password => {
    const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[(){}\[\]\+\-\=_.,:;?!@#\$%\^&\*]).{8,}$/;
    const validPassword = regExp.test(password);
    return validPassword;
};

export const validatorCreditCard = creditnum => {
    /* eslint-disable no-useless-escape */
    const cRegExp = /^(?:3[47][0-9]{13})$/;
    /* eslint-enable no-useless-escape */
    const validCreditCard = cRegExp.test(creditnum);
    return validCreditCard;
};

export const validatorUrlValidator = val => {
    if (val === undefined || val === null || val.length === 0) {
        return true;
    }
    /* eslint-disable no-useless-escape */
    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    /* eslint-enable no-useless-escape */
    return re.test(val);
};

export const validatorCNPJ = taxId => cnpj.isValid(taxId);

export const validatorTaxId = taxId => {
    const isCpfValid = cpf.isValid(taxId);

    return isCpfValid || validatorCNPJ(taxId);
};

export const validatorAfterDate = date => {
    const today = new Date();
    const dateToValidation = new Date(date);

    dateToValidation.setDate(dateToValidation.getDate() + 1);
    return (
        today.toDateString() === dateToValidation.toDateString()
      || today <= dateToValidation
    );
};

export const validatorAfterToday = date => {
    const today = new Date();
    const dateToValidation = new Date(date);

    dateToValidation.setDate(dateToValidation.getDate() + 1);
    today.setDate(today.getDate() + 1);
    return (
        today.toDateString() === dateToValidation.toDateString()
      || today <= dateToValidation
    );
};

export const validatorBeforeDate = date => new Date(date) <= new Date();

export const validatorEndDate = (date, startDate) => date > startDate;

function isFloat(x) {
    if (!isNaN(x)) {
        // eslint-disable-next-line radix
        if (parseInt(x) !== parseFloat(x)) {
            return true;
        }
    }

    return false;
}

export const validatorPerCent = value => value > 1 && value <= 100 && !isFloat(value);

export const validatorPerCentRate = value => value >= 1 && value <= 100;

export const validatorAgency = agency => agency.length === 4;

export const validatorBankAccount = account => account.length > 0 && account.length < 20 && account.includes('-');

export const validatorEffectiveValue = money => {
    const moneyWithoutSimbols = +money.replace(/[R$.]/g, '').replace(',', '.');

    return moneyWithoutSimbols >= 1;
};

export const validatorCEP = (cep, address) => {
    const city = address[0];
    const regex = /^\d{5}-\d{3}$/;
    if (!regex.test(cep)) return false;

    if (city == undefined) {
        return false;
    }
    if (city == ' undefined') {
        return false;
    }

    return true;
};

export const validatorLiquidatedManual = (manualLiquidationValue, manualLiquidationData) => {
    const removeSymbolsMoney = value => (typeof value === 'string'
        ? +value.replace(/[R$]/g, '').replace(',', '.')
        : value);
    const data = JSON.parse(manualLiquidationData);
    const {
        manual_liquidated: manualLiquidated,
        paid_value: paidValue,
        effect_value: effectValue,
    } = data;

    const manualLiquidatedNoSymbols = removeSymbolsMoney(manualLiquidated || data.manual_posting);
    return (manualLiquidatedNoSymbols ?? 0) + paidValue + removeSymbolsMoney(manualLiquidationValue) <= effectValue;
};

export const validatorCalculatorValue = (value, [availableValue]) => {
    const removeSymbolsMoney = value => (typeof value === 'string'
        ? +value.replace(/[R$ .]/g, '').replace(',', '.')
        : value);

    return removeSymbolsMoney(value) <= +(+availableValue).toFixed(2);
};
